<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <q-toolbar class="crdc-main-toolbar q-py-sm">
    <q-btn
      flat
      dense
      round
      :icon="getMenuLeftIcon"
      aria-label="Menu Left"
      class="q-ml-xs q-mr-sm"
      @click="emitToggleMenuLeft"
      v-if="$route.path !== '/'"
    />
    <q-separator vertical inset />
    <q-toolbar-title class="q-ml-sm" v-show="$q.screen.gt.xs">
      <LogoPortal
        class="cursor-pointer"
        style="width: 87px"
        @click="goToHome"
      />
    </q-toolbar-title>
    <q-item>
      <q-item-section>
        <AuthSelectProfile />
      </q-item-section>
    </q-item>
    <q-space v-show="!$q.screen.gt.xs" />

    <q-separator vertical inset />

    <q-btn-dropdown
      flat
      dense
      round
      aria-label="Menu Right"
      dropdown-icon="apps"
      class="q-ml-md q-mr-md"
      :loading="!modules"
    >
      <q-list class="q-gutter-sm">
        <q-item
          v-for="(module, i) in activeModules"
          :key="i"
          v-close-popup
          clickable
          @click.prevent="goToModule(module)"
        >
          <q-item-section avatar>
            <q-icon
              class="material-icons-outlined"
              :name="module.icon"
              color="grey-7"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label class="q-pt-sm module_nome" caption
              >{{ module.nome }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>

    <q-separator vertical inset />

    <q-btn
      flat
      dense
      round
      aria-label="Menu Right"
      @click="emitToggleMenuRight"
      class="q-ml-md q-mr-md"
    >
      <q-avatar size="40px">
        <q-img alt="Profile Picture" :src="me?.profilePicture" />
      </q-avatar>
    </q-btn>
  </q-toolbar>
</template>

<script setup>
import LogoPortal from '@/components/image/LogoPortal'
import { useBillingStore } from '@/stores/billing'
import { useProfilesStore } from '@/stores/profiles'
import { event, storage } from '@crdc/utils'
import { useQuasar } from 'quasar'
import { navigateToUrl } from 'single-spa'
import { computed } from 'vue'
import AuthSelectProfile from '../select/Profile.vue'

const $q = useQuasar()
const profilesStore = useProfilesStore()
const billingStore = useBillingStore()

const emit = defineEmits(['toggle-menu-right'])
const props = defineProps({
  isMenuLeftOpen: {
    type: Boolean,
    default: false,
  },
})

const profile = computed(() => {
  return profilesStore.getProfile
})

const modules = computed(() => {
  return billingStore.modules
})

const me = computed(() => {
  return storage.get('me')
})

const allModules = computed(() => {
  return [
    {
      id: 1,
      icon: 'lock',
      key: 'accessManagement',
      pagePath: '/access-management',
    },
    {
      id: 2,
      icon: 'check',
      key: 'formalization',
      pagePath: '/formalization-management',
    },
    {
      id: 3,
      icon: 'article',
      key: 'documentManagement',
      pagePath: '/document-management',
    },
    {
      id: 4,
      icon: 'edit',
      key: 'ballastManagement',
      pagePath: '/ballast-management',
    },
    {
      id: 5,
      icon: 'listItemIcon',
      key: 'duplicatesManagement',
      pagePath: '/duplicate-management',
    },
    {
      id: 6,
      icon: 'settings',
      key: 'configuration',
      pagePath: '/configuration',
    },
  ]
})
const getMenuLeftIcon = computed(() => {
  return props.isMenuLeftOpen ? 'close' : 'menu'
})
const activeModules = computed(() => {
  const modulesProfile = modules.value?.filter((m) =>
    profile.value.modulos?.some((mp) => mp.chave == m.chave)
  )
  const t = modulesProfile?.map((all) => {
    return {
      ...all,
      ...allModules.value.filter((mo) => mo.key == all.chave)[0],
    }
  })
  return t
})
function emitToggleMenuLeft() {
  event.add('NAVBAR:TOOLBAR:TOGGLE_MENU_LEFT')
}
function emitToggleMenuRight() {
  emit('toggle-menu-right')
}
function goToHome() {
  navigateToUrl('/')
}
function goToModule({ pagePath }) {
  const baseURL = window.location.origin
  window.location = `${baseURL}${pagePath}`
}
</script>

<style lang="scss" scoped>
.crdc-main-toolbar {
  background: linear-gradient(270.01deg, #0095cf 0%, #005090 99.99%);
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container-apps {
  padding: 10px;
  height: 300px;
  width: 300px;
}

.module_nome {
  font-size: 1rem;
  color: #000000;
}
</style>
