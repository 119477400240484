<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <q-btn-dropdown
    outline
    no-caps
    :disable-dropdown="disableDropdown"
    :loading="loading"
    class="dropdown-header"
    dropdown-icon="expand_more"
  >
    <template v-slot:label>
      <div class="row items-center no-wrap q-gutter-sm" :title="getLabel">
        <div
          v-if="$q.screen.gt.xs"
          class="text-center"
          style="width: 180px; overflow: hidden; text-overflow: ellipsis"
        >
          {{ getLabel }}
        </div>
        <div v-else class="text-center" style="float: left">Perfil</div>
      </div>
    </template>

    <q-list>
      <q-item
        v-for="(p, i) in profiles"
        :key="i"
        v-close-popup
        clickable
        :active="
          inputVal && p.id === inputVal.id && p.idEmpresa === inputVal.idEmpresa
        "
        active-class="bg-teal-1 text-grey-8"
        @click="changeUserProfile(p)"
      >
        <q-item-section>
          <q-item-label>{{ p.perfil }}</q-item-label>
          <q-item-label v-if="p" caption>
            {{ p.razaoSocial }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script setup>
import { i18n } from '@/boot/i18n'
import { useNotifyStore } from '@/stores/notify.js'
import { useProfilesStore } from '@/stores/profiles'
import { storage } from '@crdc/utils'
import { useQuasar } from 'quasar'
import { navigateToUrl } from 'single-spa'
import { computed, onMounted, ref } from 'vue'

const $q = useQuasar()
const notifyStore = useNotifyStore()
const profilesStore = useProfilesStore()
const loading = ref(false)

onMounted(async () => {
  if (me.value) {
    loading.value = true
    $q.loading.show({
      message: i18n.global.t('LOADING.DEFAULT'),
    })
    try {
      try {
        return await Promise.all([
          profilesStore.fetchAllProfilesByUser(me.value),
        ])
      } catch (error) {
        notifyStore.fetchError(error.message)
      }
    } finally {
      loading.value = false
      $q.loading.hide()
    }
  }
})

const profiles = computed(() => {
  return profilesStore.profiles
})
const inputVal = computed(() => {
  return profilesStore.profile
})
const me = computed(() => {
  return storage.get('me')
})

const getLabel = computed(() => {
  if (!inputVal.value) {
    return i18n.global.t('LABEL.SELECT')
  }
  const label = [inputVal.value.perfil, inputVal.value.razaoSocial]
  return label.join(' - ')
})
const disableDropdown = computed(() => {
  return inputVal.value && profiles.value?.length < 2
})
const isHome = computed(() => {
  const baseURL = window.location.href
  const isHome = baseURL.split('/') && baseURL.split('/').length < 2
  return isHome
})

function changeUserProfile(profile) {
  try {
    loading.value = true
    $q.loading.show({
      message: i18n.global.t('LOADING.DEFAULT'),
    })
    if (
      profile.id !== inputVal.value.id ||
      profile.idEmpresa !== inputVal.value.idEmpresa
    ) {
      profilesStore.changeProfile({ ...profile, uuid: me.value.uuid })

      if (!isHome.value) goToHome()
    }
  } catch (error) {
    notifyStore.fetchError(error.message)
  } finally {
    loading.value = false
    $q.loading.hide()
  }
}
// function getColor(profile) {
//   if (profile) {
//     const colors = [
//       'blue-grey-3',
//       'blue',
//       'cyan-6',
//       'green',
//       'light-green',
//       'yellow',
//       'orange',
//       'light-blue-4',
//     ]
//     return colors[(parseInt(profile.id, 10) - 1) % colors.length]
//   }
//   return ''
// }
function goToHome() {
  navigateToUrl('/')
}
</script>

<style lang="scss" scoped>
.dropdown-header {
  color: #90caf9;
  text-transform: uppercase;
  .q-btn--outline:before {
    border: 1px solid #48aadf !important;
  }
}
</style>
