import { defineStore } from 'pinia'
import { api } from '@crdc/utils'
import { useNotifyStore } from './notify'

const API_BILLING = '/billing/v1'

export const useBillingStore = defineStore('billing', {
  state: () => ({
    modules: [],
  }),
  getters: {
    getModules: (state) => state.modules,
  },
  actions: {
    fetchAllModulesById(payload) {
      this.modules = undefined
      return api
        .get(`${API_BILLING}/modulos/contratante/${payload}`)
        .then(({ data }) => {
          this.modules = data
          return data
        })
        .catch((error) => {
          useNotifyStore().fetchError(error.message)
          return error
        })
    },
  },
})
