<template>
  <q-drawer
    v-model="inputVal"
    side="right"
    overlay
    behavior="mobile"
    content-class="bg-blue-grey-14 text-white q-py-sm"
  >
    <q-list>
      <q-item class="row justify-center">
        <q-item-section side>
          <q-avatar size="48px">
            <q-img alt="Profile Picture" :src="me?.profilePicture" />
          </q-avatar>
        </q-item-section>
      </q-item>

      <q-item class="row justify-center">
        <q-item-section side>
          <q-item-label class="text-h6 color-name"
            >{{ me?.firstname }} {{ me?.lastname }}</q-item-label
          >
        </q-item-section>
      </q-item>

      <q-item class="row justify-center username-style">
        <q-item-section side>
          <q-item-label class="text-h7">{{ '@' + me.username }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>

    <q-separator inset />

    <q-scroll-area class="scroll-area">
      <q-list>
        <q-item clickable @click="emitLogout()">
          <q-item-section>
            <q-item-label class="logout-style">
              {{ $t('PAGE.LOGOUT') }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import { storage } from '@crdc/utils'
import { mapActions } from 'pinia'
import { navigateToUrl } from 'single-spa'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DrawerAccount',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {}
  },
  computed: {
    me() {
      return storage.get('me')
    },
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    ...mapActions(useAuthStore, {
      signOut: 'signOut',
    }),
    emitLogout() {
      this.$q.loading.show({
        message: this.$t('LOADER.LOGOUT'),
      })
      this.signOut()
        .then(() => {
          navigateToUrl('/auth/login')
        })
        .finally(() => {
          this.$q.loading.hide()
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.color-name {
  color: #005090;
}

.username-style {
  padding-top: 0px;
  margin-top: -10px;
}

.scroll-area {
  height: calc((100% - 16px) - 130px);
}

.logout-style {
  color: $negative;
}
</style>
