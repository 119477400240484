import { useBillingStore } from '@/stores/billing'
import { useUserStore } from '@/stores/user'
import { api, event, storage } from '@crdc/utils'
import { defineStore } from 'pinia'
import { useNotifyStore } from './notify'

const API_PERFIL = '/perfil/v1'

export const useProfilesStore = defineStore('profiles', {
  state: () => ({
    profile: {},
    profiles: [],
    profileTypes: [],
    notifyStore: useNotifyStore(),
  }),
  getters: {
    getProfile: (state) => state.profile,
    getProfiles: (state) => state.profiles,
    getProfileTypes: (state) => state.profileTypes,
  },
  actions: {
    changeProfile(payload) {
      storage.set('perfil', payload.id)
      storage.set('sub', payload.uuid)
      storage.set('perfil-group-user', payload.idPerfilGrupoUsuario)
      this.profile = payload
      storage.set('user-modules', payload)
      useUserStore().saveProfileInUse(payload)
      useBillingStore().fetchAllModulesById(payload.idContratante)
      event.add('NAVBAR:STORES:PROFILE:UPDATE')
    },
    fetchProfileTypesById(payload) {
      return api
        .get(`${API_PERFIL}/perfil/tipos/${payload.id}`)
        .then(({ data }) => {
          this.profileTypes = data
          return data
        })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    fetchAllProfilesByUser(payload) {
      return api
        .get(`${API_PERFIL}/perfil/${payload?.uuid}`)
        .then(({ data }) => {
          this.profiles = data

          const perfilLocalStorage = storage.get('perfil-group-user')
          if (!perfilLocalStorage) {
            this.changeProfile({ ...data[0], uuid: payload.uuid })
          } else {
            const findProfile = data.find(
              (p) => p.idPerfilGrupoUsuario === perfilLocalStorage
            )
            const profile = findProfile ? findProfile : data[0]
            this.changeProfile({ ...profile, uuid: payload.uuid })
          }
          return data
        })
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
    deleteProfileUserById(payload) {
      return api
        .delete(`${API_PERFIL}/perfilGrupoUsuario/desassociar/${payload}`)
        .catch((error) => {
          this.notifyStore.fetchError(error.message)
        })
    },
  },
})
