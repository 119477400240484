import './styles/quasar.sass'
import lang from 'quasar/lang/pt-BR.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import '@quasar/extras/roboto-font/roboto-font.css'
import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Loading,
    Dialog,
    Notify,
  },
  lang: lang,
}
