import { i18n as messages } from '@crdc/utils'
import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: 'pt-Br',
  fallbackLocale: 'pt-Br',
  numberFormats: {
    'pt-Br': {
      currency: {
        style: 'currency',
        currency: 'BRL',
      },
    },
  },
  messages,
})

export default boot(({ app }) => {
  app.use(i18n)
})

export { i18n }
